/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/r2-ga/"
  }, "R2 is now Generally Available - The Cloudflare Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/turnstile-private-captcha-alternative/"
  }, "Announcing Turnstile, a user-friendly, privacy-preserving alternative to CAPTCHA - The Cloudflare Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/moving-from-recaptcha-to-hcaptcha/"
  }, "Moving from reCAPTCHA to hCaptcha - The Cloudflare Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/how-we-built-pingora-the-proxy-that-connects-cloudflare-to-the-internet/"
  }, "How we built Pingora, the proxy that connects Cloudflare to the Internet - The Cloudflare Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/whats-new-with-d1/"
  }, "D1: our quest to simplify databases - The Cloudflare Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/the-first-zero-trust-sim/"
  }, "The first Zero Trust SIM - The Cloudflare Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.adobe.com/news/news-details/2022/Adobe-to-Acquire-Figma/default.aspx"
  }, "Adobe to Acquire Figma - Adobe")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.dropbox.com/topics/company/welcome-command-e-to-dropbox-"
  }, "Welcome Command E to Dropbox! - Dropbox Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/changelog/2022-09-23-local-timezones-available-on-profiles/"
  }, "Local timezones available on profiles - The GitHub Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20220930009400091?input=1195m"
  }, "비용 삭감 나서는 구글, 게임 스트리밍 플랫폼 서비스 중단 - 연합뉴스")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
